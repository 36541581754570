/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var attr = $('.page-header').attr('style');
        if (typeof attr !== typeof undefined && attr !== false && attr !== '') {
          $('body').addClass('has-hero-image');
        }

        $('.home-slider .carousel').carousel({pause: false});

        $('.title-links a[href^=#]').each(function() {
          $(this).addClass('launch-modal');
        });

        $(document).on('keyup', function(e) {
          if(e.which === 39){
            $('.home-slider .carousel').carousel('next');
            if ($('section.whats-happening').hasClass('shown')) {
              $('#specials-carousel').carousel('next');
            }
          }
          else if(e.which === 37){
            $('.home-slider .carousel').carousel('prev');
            if ($('section.whats-happening').hasClass('shown')) {
              $('#specials-carousel').carousel('prev');
            }
          }
        });

        if ($('section.layout').hasClass('black') || $('body').hasClass('error404') || $('body').hasClass('search')) {
          $('body').addClass('black');
        } else {
          $('body').addClass('white');
        }

        $('#specials-carousel').carousel();

        $(window).load(function() {
          $('.carousel div.special').each(function() {
            $(this).css('height', $(this).height());
          });
        });
        
        // $('#specials-carousel .item .special.repeated:last-of-type').closest('.item').prepend($('#specials-carousel .item .special.repeated:last-of-type'));
        // $('#specials-carousel .item .special.repeated:last-of-type').closest('.item').prepend($('#specials-carousel .item .special.repeated:last-of-type'));

        // if($(window).width() < 1200) {
        //   $('#specials-carousel .item .special.repeated').remove();
        // }

        if($(window).width() > 1199 && false) { // Not cloning items anymore
          $('#specials-carousel .item').each(function(){
            var next = $(this).next();
            if (!next.length) {
              next = $(this).siblings(':first');
            }
            next.children(':first-child').clone().appendTo($(this));
            
            for (var i=0;i<3;i++) {
              next=next.next();
              if (!next.length) {
                next = $(this).siblings(':first');
              }
              
              next.children(':first-child').clone().appendTo($(this));
            }
          });
        }

        if($(window).width() < 1200) {
          var slidesAmount = $('#specials-carousel .item .special').length;
          var itemsAdded = $('#specials-carousel .item').length;

          while (itemsAdded < slidesAmount) {
            $('#specials-carousel .carousel-inner').append('<div class="item"></div>');
            itemsAdded++;
          }

          $('#specials-carousel .item').each(function(index, element){

            while ($(this).find('.special').length > 1) {
              $(this).next().append($(this).find('.special:first-child').next());
            }
          });
        }

        $('li.menu-item.launch-modal a').click(function() {
          if($(window).width() < 768) {
            $('button.navbar-toggle').trigger('click');
          }
        });

        $('.menu-item-has-children').click(function() {
          var subMenu = $(this).find('ul.sub-menu');
          if(!subMenu.hasClass('shown')) {
            subMenu.addClass('shown');
          } else {
            subMenu.removeClass('shown');
          }
        });

        $('a.launch-modal, .launch-modal a, a[href="#catering-order"]').click(function(e) {
          e.preventDefault();
          $($(this).attr('href')).addClass('shown');
          $($(this).attr('href')).removeClass('page-load');
          $('.navbar-toggle').collapse({toggle: false});
          $('.navbar-collapse').trigger('collapse');
          $('html, body').addClass('modal-open');
        });

        $('.dismiss, .dismiss-no-text').click(function() {
          $('#' + $(this).attr('data-modal')).removeClass('shown');
          $('html, body').removeClass('modal-open');
        });

/*      FODA team asked us to target the link to external site instead of revealing form.
        $('a.sign-up-news-letter').click(function() {
          $(this).closest('section').addClass('hidden');
          $('footer .gform_wrapper').addClass('shown');
        });
*/
        $('#return-to-top').click(function(e) {
          e.preventDefault();
          $(window).scrollTo(0, 0);
        });

        $('.navbar-toggle').click(function(){
          $('.whats-happening').removeClass('shown');
        });

        if($(window).width() < 768) {
          if ($('body').hasClass('has-online-order-button'))
            $('.menu-primary-menu-container').height($(window).height() - 90 - 112);
          else
            $('.menu-primary-menu-container').height($(window).height() - 90 - 85);

          $('.navbar-toggle').click(function(){
            if(!$('header').hasClass('fixed')) {
              $('header').addClass('fixed');
            } else {
              $('header').removeClass('fixed');
            }
          });
        }

        $(".home-slider .carousel, .whats-happening .specials-carousel").swiperight(function() {  
          $(this).carousel('prev');  
        });
        
        $(".home-slider .carousel, .whats-happening .specials-carousel").swipeleft(function() {  
          $(this).carousel('next');  
        });

        $(window).scroll(_.throttle(function(){
          var scroll = $(window).scrollTop();
          var footerThreshold = $(window).height();

          //scroll-to-top arrow position when user scrolls all the way to footer
          if ($(window).width() > 991) {
            if (scroll > $('footer').offset().top-footerThreshold) {
              $('#return-to-top').addClass("fixed");
            } else {
              $('#return-to-top').removeClass("fixed");
            }
          }

        }, 50));
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
